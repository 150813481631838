<template>
  <header>
    <nav class="navbar navbar-expand-md">
      <div class="desktop-navbar w-100">
        <div class="container">
          <router-link class="navbar-brand" to="/">
            <i class="fa fa-film" aria-hidden="true"></i>
          </router-link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto"></ul>
            <slot name="desktop-nav"></slot>
            <li class="navbar-nav nav-item dropdown profile-dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-user" aria-hidden="true"></i>
              </a>
              <div id="navbar-profile-menu" class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="/account-settings" data-toggle="modal" data-target="#account-settings">Account Settings</a>
                <a class="dropdown-item" @click="logout">Logout</a>
              </div>
            </li>
          </div>
        </div>
      </div>

      <div class="mobile-navbar w-100">
        <slot name="mobile-nav"></slot>
      </div>
    </nav>
    <AccountSettings />
    <div v-if="!emailVerified" class="mt-3 row justify-content-center">
      <div class="col-6 text-center">
        <VerifyEmailBanner />
      </div>
    </div>
  </header>
</template>

<script>
import AccountSettings from "./AccountSettings";
import VerifyEmailBanner from "./VerifyEmailBanner";
import { emailVerified } from "../store/helpers";

export default {
  name: "NavBar",
  components: {
    AccountSettings,
    VerifyEmailBanner
  },
  computed: {
    ...emailVerified
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
</script>

<style scoped>
/*                    */
/*      HEADER        */
/*                    */
nav.navbar {
  background-color: #30323d;
  font-size: 20px;
}

.navbar-brand i {
  font-size: 50px;
  margin-right: 15px;
}

.mobile-navbar {
  display: none;
}

.nav-item {
  border-right: 1px solid #fff;
  border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(226, 226, 226, 0.75) 50%, rgba(255, 255, 255, 0) 100%);
  border-image-slice: 1;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #e8c547;
}

#navbarDropdown {
  font-size: 30px;
  padding: 0px 0 0 0;
}

#navbar-profile-menu.dropdown-menu {
  top: 130%;
  border-radius: 0;
  background-color: #6a72a0;
  background-image: url("https://www.transparenttextures.com/patterns/tweed.png");
  border: solid #393d55 5px !important;
}

#navbarDropdown.dropdown-toggle::after {
  /* margin-left: .255em; */
  margin-left: 0;
}

#navbarDropdown .fa-user {
  vertical-align: baseline;
}

.nav-item:last-of-type {
  border-right: none;
}

.profile-dropdown {
  margin-left: 20px;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .navbar {
    position: fixed;
    width: 100%;
    bottom: 0px;
    z-index: 123;
    padding: 0px 0px env(safe-area-inset-bottom);
  }

  .desktop-navbar {
    display: none;
  }

  .mobile-navbar {
    display: flex;
    justify-content: space-around;
    height: 56px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}
</style>
