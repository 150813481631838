<template>
  <div class="user-card text-center py-3">
    <div v-if="editMode">
      <a class="delete-icon" v-if="editable" @click="deleteUser"><i class="fas fa-trash-alt"></i></a>
      <i class="fas fa-user-circle user-icon"></i>
      <a class="save-icon" v-if="editable" @click="saveUser"><i class="far fa-save"></i></a>
      <h5>{{ user.displayName }}</h5>
      <select name="role-select" id="role-select" class="form-control form-control-sm" v-model="role">
        <option v-for="role in selectableRoles" :key="role" :value="role">{{ role.roleName }}</option>
      </select>
    </div>
    <div v-if="!editMode">
      <i class="fas fa-user-circle user-icon"></i>
      <a class="edit-icon" v-if="editable" @click="editUser"><i class="fas fa-pencil-alt"></i></a>
      <h5>{{ user.displayName }}</h5>
      <small>{{ role.roleName }}</small>
    </div>
  </div>
</template>

<script>
import { userId } from "../../store/helpers";
import { Role, hasRole } from "../../utilities/list.role";

export default {
  name: "UserCard",
  props: {
    user: Object,
    selectableRoles: Object
  },
  data() {
    return {
      editable: false,
      editMode: false,
      role: null
    };
  },
  emits: ["save-user", "delete-user"],
  inject: ["userRole"],
  computed: {
    ...userId
  },
  methods: {
    editUser() {
      this.editMode = true;
    },
    saveUser() {
      this.editMode = false;
      this.$emit("save-user", this.user.id, this.role.id);
    },
    deleteUser() {
      this.$emit("delete-user", this.user);
    }
  },
  created() {
    this.role = this.user.role;

    let isSelf = this.userId == this.user.id;

    if (!hasRole(this.user.role.id, Role.OWNER) && !isSelf) {
      this.editable = hasRole(this.userRole.value.id, Role.ADMIN);
    }
  }
};
</script>

<style scoped>
.user-card .user-icon {
  font-size: 70px;
}

.user-card h5 {
  font-size: 20px;
  margin: 0;
}

.user-card .edit-icon,
.user-card .save-icon,
.user-card .delete-icon {
  position: absolute;
  color: lightgray;
}

.user-card .save-icon,
.user-card .edit-icon {
  right: 20px;
}

.user-card .delete-icon {
  left: 20px;
}

.user-card .edit-icon {
  display: none;
}

.user-card:hover .edit-icon {
  display: inline-block;
}

.user-card:hover .edit-icon:hover,
.user-card:hover .save-icon:hover {
  color: whitesmoke;
}

#role-select {
  font-size: 12.8px;
  width: 100%;
  text-align-last: center;
}
</style>
