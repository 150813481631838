<template>
  <form class="form-signin" id="app">
    <p v-cloak style="color: red;">{{ errorMessage }}</p>
    <label for="email" class="sr-only">Email address</label>
    <input type="email" id="email" name="email" class="form-control" placeholder="Email address" required autofocus v-model="email" />
    <label for="password" class="sr-only">Password</label>
    <input type="password" id="password" name="password" class="form-control" placeholder="Password" required v-model="password" />
    <div class="checkbox mb-3">
      <label class="text-white">
        <input type="checkbox" value="remember-me" />
        Remember me
      </label>
    </div>
    <button type="button" class="btn btn-lg btn-primary btn-block" v-on:click="authenticate">
      Sign in
    </button>
    <div class="mt-3">
      <a @click="resetModalOpen = true" href="#">Reset Password</a>
      <ForgotPasswordModal :open="resetModalOpen" @closed="resetModalOpen = false" />
    </div>
  </form>
</template>

<script>
import ForgotPasswordModal from "@/components/ForgotPasswordModal.vue";
import { generateErrorMessage } from "../utilities/utilities";

export default {
  name: "LoginForm",
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      resetModalOpen: false
    };
  },
  components: {
    ForgotPasswordModal
  },
  methods: {
    authenticate() {
      if (this.password.length <= 0) {
        this.errorMessage = "Please enter your password";
        return;
      }

      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch(err => {
          this.errorMessage = generateErrorMessage(err);
        });
    }
  }
};
</script>

<style scoped>
/*                    */
/*      Login         */
/*                    */
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
