<template>
  <div class="alert alert-warning">
    Your email address '{{ email }}' is not verified. Please click the link in the verification email or request a new one
    <a @click="resendVerificationEmail" class="alert-link">here</a>
  </div>
</template>

<script>
import AccountService from "../services/AccountService";
import { userId, email } from "../store/helpers";

export default {
  name: "VerifyEmailBanner",
  computed: {
    ...userId,
    ...email
  },
  methods: {
    resendVerificationEmail() {
      AccountService.resendVerificationEmail()
        .then(() => {
          this.$toast.success("Email verification email sent successfully");
        })
        .catch(error => {
          error.handleError();
        });
    }
  }
};
</script>

<style scoped>
.alert-link {
  cursor: pointer;
}
</style>
