<template>
  <div>
    <NavBar>
      <template v-slot:desktop-nav>
        <div v-cloak class="d-flex">
          <ItemSearch :listId="listId" />
          <router-link v-if="userRole && hasRole(Role.MEMBER)" class="btn btn-yellow btn-nav my-2 my-sm-0 ml-2" :to="'/lists/' + listId + '/order'">
            <i class="fas fa-sort-amount-up"></i>
          </router-link>
        </div>
      </template>
      <template v-slot:mobile-nav>
        <router-link class="mobile-nav-icon btn-nav btn btn-yellow w-100" :to="'/lists/' + listId">
          <i class="fas fa-arrow-alt-circle-left"></i>
          Back
        </router-link>
        <router-link v-if="userRole && hasRole(Role.MEMBER)" class="mobile-nav-icon btn-nav-yellow btn btn-yellow w-100" :to="'/lists/' + listId + '/order'">
          <i class="fas fa-sort-amount-up"></i>
          Order
        </router-link>
      </template>
    </NavBar>
    <div v-cloak class="container home">
      <div class="movie-list-header">
        <h1>Search: {{ query }}</h1>
      </div>
      <ItemSearch v-if="userRole && hasRole(Role.MEMBER)" class="w-100 mb-3 d-md-none" :listId="listId" />
      <div class="row search-group" v-if="listId" v-cloak>
        <div class="col-xl-4 col-md-6" v-for="item in items" :key="item">
          <SearchItem :item="item" @add-item="addItem" @remove-item="removeItem" :listId="listId" />
        </div>
        <div class="col-12" v-if="items && items.length == 0">
          <h2 class="text-center">
            No items found
            <i class="fas fa-frown"></i>
            <br />
            Please try a different search term.
            <br />
            <small>e.g. Avengers Endgame</small>
          </h2>
        </div>
      </div>
      <div v-else>
        <h4>Invalid ListId</h4>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import ItemSearch from "@/components/ItemSearch.vue";
import SearchItem from "@/components/SearchItem.vue";
import ItemService from "../services/ItemService";
import ListService from "../services/ListService";
import { Role, hasRole } from "../utilities/list.role";

export default {
  name: "Items",
  data() {
    return {
      items: null,
      Role,
      userRole: null
    };
  },
  components: {
    NavBar,
    ItemSearch,
    SearchItem
  },
  computed: {
    listId: function() {
      return this.$route.query.listId;
    },
    query: function() {
      return this.$route.query.query;
    }
  },
  methods: {
    hasRole(role) {
      return hasRole(this.userRole.id, role);
    },
    addItem(item) {
      ListService.addItemToList(this.listId, item.imdbID)
        .then(result => {
          if (result == "success") {
            item.isInList = !item.isInList;
            this.$toast.success("Added " + item.Title);
          } else {
            this.$toast.console.error("Error adding " + item.Title);
          }
        })
        .catch(error => {
          return error.handleError();
        });
    },
    removeItem(item) {
      ListService.removeItemFromList(this.listId, item.imdbID)
        .then(result => {
          if (result == "success") {
            item.isInList = !item.isInList;
            this.$toast.success("Removed " + item.Title);
          } else {
            this.$toast.console.error("Error removing " + item.Title);
          }
        })
        .catch(error => {
          return error.handleError();
        });
    }
  },
  beforeRouteEnter(to, _from, next) {
    ListService.getList(to.query.listId)
      .then(list => {
        ItemService.searchItems(to.query.listId, to.query.query)
          .then(items => {
            next(vm => {
              vm.items = items;

              vm.userRole = list.userRole;
            });
          })
          .catch(error => {
            return error.handleError();
          });
      })
      .catch(error => {
        return error.handleError();
      });
  },
  beforeRouteUpdate(to, _from, next) {
    this.items = null;

    ListService.getList(to.query.listId)
      .then(list => {
        ItemService.searchItems(to.query.listId, to.query.query)
          .then(items => {
            this.items = items;
            this.userRole = list.userRole;
            next();
          })
          .catch(error => {
            return error.handleError();
          });
      })
      .catch(error => {
        return error.handleError();
      });
  }
};
</script>
<style scoped></style>
