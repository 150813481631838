<template>
  <div class="modal fade confirmation-modal" :id="modalId" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content item-card">
        <div class="modal-header">
          <h2 class="modal-title">{{ title }}</h2>
        </div>
        <div class="modal-body">
          <p>{{ text }}</p>
          <form class="create-list container-fluid">
            <div class="form-group row">
              <label for="deleteConfirmInput">Type: {{ value }}</label>
              <input type="text" class="form-control" id="deleteConfirmInput" v-model="userInput" :placeholder="value" />
              <div class="invalid-feedback">
                Your input does not match.
              </div>
            </div>
          </form>
          <div class="confirmation-buttons">
            <a class="btn btn-green" @click="close">Cancel</a>
            <a class="btn btn-red" @click="confirmDelete">{{ buttonText }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { generateRandomId } from "../utilities/utilities";

export default {
  name: "DeleteConfirm",
  props: {
    value: String,
    open: Boolean,
    title: {
      type: String,
      default: "Delete Confirm"
    },
    text: {
      type: String,
      default: "Please retype the name in order to delete it!"
    },
    buttonText: {
      type: String,
      default: "Delete"
    }
  },
  watch: {
    open: function(val) {
      if (val) {
        this.userInput = "";
        $(`#${this.modalId}`).modal("show");
      } else {
        $(`#${this.modalId}`).modal("hide");
      }
    }
  },
  emits: ["delete-confirmed", "closed"],
  data() {
    return {
      userInput: "",
      modalId: generateRandomId("Delete-Confirm")
    };
  },
  methods: {
    confirmDelete() {
      if (this.value != this.userInput) {
        $(`#${this.modalId} #deleteConfirmInput`).addClass("is-invalid");
      } else {
        $(`#${this.modalId}`).modal("hide");
        this.$emit("delete-confirmed");
      }
    },
    close() {
      $(`#${this.modalId}`).modal("hide");
    }
  },
  mounted() {
    var self = this;

    if (this.open) {
      $(`#${this.modalId}`).modal("show");
    }

    $(`#${this.modalId}`).on("hide.bs.modal", function() {
      self.$emit("closed");
    });
  }
};
</script>

<style scoped></style>
