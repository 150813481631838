import { auth } from "../store/auth";
import { signInWithEmailAndPassword } from "firebase/auth";

export default {
  authenticate(email, password) {
    return signInWithEmailAndPassword(auth, email, password).then(response => {
      return response.user;
    });
  }
};
