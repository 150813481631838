<template>
  <div id="account-settings-container">
    <div class="modal fade confirmation-modal" id="account-settings" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content item-card">
          <div class="modal-header">
            <h2 class="modal-title">Account Settings</h2>
          </div>
          <div class="modal-body">
            <h3>Details</h3>
            <div class="row">
              <div class="col-9 align-self-center">
                <div class="form-group row">
                  <label for="displayNameInput" class="col-sm-4 col-form-label">Display Name</label>
                  <div class="col-sm-8">
                    <input type="text" v-model="displayNameInput" class="form-control" id="displayNameInput" placeholder="Please enter a display name" />
                  </div>
                </div>
                <div class="form-group row">
                  <label for="emailInput" class="col-sm-4 col-form-label">Email address</label>
                  <div class="col-sm-8">
                    <input type="email" v-model="emailInput" class="form-control" id="emailInput" placeholder="Please enter an email address" />
                    <div class="invalid-feedback">
                      This is not a valid email address
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3 text-center mb-3">
                <div>
                  <i id="user-image" class="fas fa-user-circle user-icon"></i>
                </div>
                <a class="btn btn-yellow mt-3 btn-sm" @click="changePhoto">Change Photo</a>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-9">
                <h3>Delete Account</h3>
                <p>Deleting your account is permanent and all of your rankings and movie lists will no-longer be accessible.</p>
              </div>
              <div class="col-3 align-self-center text-center">
                <a class="btn btn-red text btn-sm" @click="confirmDelete">Delete Account</a>
              </div>
            </div>
          </div>
          <div class="modal-footer confirmation-buttons">
            <a class="btn btn-red" data-dismiss="modal">Close</a>
            <a class="btn btn-green" @click="saveDetails">Save</a>
          </div>
        </div>
      </div>
    </div>
    <DeleteConfirm @delete-confirmed="deleteAccount" @closed="openConfirm = false" value="Delete My Account" :open="openConfirm" />
  </div>
</template>

<script>
import $ from "jquery";
import DeleteConfirm from "./DeleteConfirm";
import AccountService from "../services/AccountService";
import { userId } from "../store/helpers";
import { isValidEmail } from "../utilities/validation";

export default {
  name: "AccountSettings",
  props: ["open"],
  emits: ["closed"],
  components: {
    DeleteConfirm
  },
  computed: {
    ...userId
  },
  data() {
    return {
      emailInput: this.$store.state.user.email,
      displayNameInput: this.$store.state.user.displayName,
      openConfirm: false
    };
  },
  methods: {
    changePhoto() {
      this.$toast.warning("This feature is not implemented yet.<br>Please try again later...");
    },
    saveDetails() {
      // Validation
      if (isValidEmail(this.emailInput)) {
        $("#emailInput").removeClass("is-invalid");

        this.$store
          .dispatch("saveDeatils", {
            email: this.emailInput,
            displayName: this.displayNameInput
          })
          .then(() => {
            this.$toast.success("Account settings saved successfully");
            $("#account-settings").modal("hide");
          })
          .catch(error => {
            error.handleError();
          });
      } else {
        $("#emailInput").addClass("is-invalid");
      }
    },
    confirmDelete() {
      $("#account-settings").modal("hide");

      this.openConfirm = true;
    },
    deleteAccount() {
      AccountService.deleteAccount(this.userId)
        .then(() => {
          this.$toast.success("Account deleted successfully");

          this.$store.dispatch("logout");
        })
        .catch(error => {
          error.handleError();
        });
    }
  }
};
</script>

<style scoped>
#user-image {
  font-size: 80px;
}
</style>
