import "bootstrap/dist/css/bootstrap.min.css";
import "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "@fortawesome/fontawesome-free/css/all.css";
import "nprogress/nprogress.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toaster from "@meforma/vue-toaster";
import NProgress from "nprogress";
import { Vue3Plausible } from "vue-plausible";
import "./registerServiceWorker";

NProgress.configure({ showSpinner: false });

store.dispatch("checkAuth").then(() => {
  createApp(App)
    .use(store)
    .use(router)
    .use(Toaster, {
      position: "top-right",
      pauseOnHover: true
    })
    .use(Vue3Plausible, {
      domain: process.env.VUE_APP_DOMAIN,
      apiHost: process.env.VUE_APP_PLAUSIBLE_API,
      enableAutoPageviews: true,
      enableAutoOutboundTracking: false,
      trackLocalhost: false
    })
    .mount("#app");
});
