<template>
  <router-link :to="'/items/' + prediction.item.id" class="item-card item-card-action">
    <img class="mx-auto mb-lg-0 mb-3" :src="prediction.item.poster" alt="" />
    <div class="movie-item-body mb-sm-0 mb-3">
      <div class="flex">
        <h2 class="mb-1">
          {{ prediction.item.title }}
          <small class="year">({{ prediction.item.year }})</small>
        </h2>
      </div>
      <p class="mb-1 d-none d-sm-block">{{ prediction.item.plot }}</p>
      <div class="prediction-reason rounded p-2 mt-2">
        <h5 class="m-0">Prediction Reason</h5>
        <p class="m-0">{{ prediction.predictionReason }}</p>
      </div>
      <p class="mb-1 d-block d-sm-none">{{ truncateText(100) }}</p>
      <div class="movie-ratings">
        <small v-for="rating in prediction.item.itemRatings" :key="rating">
          <b>{{ rating.ratingSource.ratingName }}</b>
          :
          <span>{{ rating.ratingValue }}</span>
          <span class="d-none d-md-inline">•</span>
          <div class="d-inline d-md-none"><br /></div>
        </small>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ItemCardLarge",
  props: {
    prediction: Object
  },
  methods: {
    truncateText(num) {
      if (this.prediction.item.plot.length > num) {
        let subString = this.prediction.item.plot.slice(0, num);
        return subString.substr(0, Math.min(subString.length, subString.lastIndexOf(" "))) + "...";
      } else {
        return this.prediction.item.plot;
      }
    }
  }
};
</script>

<style scoped>
.movie-item-body {
  margin-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.prediction-reason {
  background: #ffffff4a;
  font-size: 13px;
}

.movie-ratings {
  margin-top: auto;
  margin-bottom: 5px;
}

.movie-ratings small b {
  color: #e8c547 !important;
}

.prediction-reason-icon {
  position: absolute;
  font-size: 1.5rem;
  bottom: 20px;
  left: 20px;
  font-size: 20px;
  color: #c7c7c7;
}

.prediction-reason-icon:hover {
  color: #e8c547;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .item-card small.date {
    left: 20px;
    bottom: 10px;
  }
}
</style>
