<template>
  <div>
    <NavBar>
      <template v-slot:desktop-nav>
        <div v-cloak class="d-flex">
          <ItemSearch :listId="listId" />
          <router-link class="btn btn-yellow btn-nav my-2 my-sm-0 ml-2" :to="'/lists/' + listId + '/order'"><i class="fas fa-sort-amount-up"></i></router-link>
        </div>
      </template>
      <template v-slot:mobile-nav v-if="item">
        <router-link v-if="item.isInList != null" class="mobile-nav-icon btn-nav btn btn-yellow flex-grow-1" :to="'/lists/' + listId">
          <i class="fas fa-arrow-alt-circle-left"></i>
          Back
        </router-link>
        <a
          target="_blank"
          v-bind:href="'https://www.youtube.com/results?search_query=' + item.title + ' Trailer'"
          class="mobile-nav-icon btn-nav-green btn btn-yellow flex-grow-1"
        >
          <i class="fas fa-film"></i>
          View Trailer
        </a>
        <a v-if="!item.isInList" @click="addItem" class="mobile-nav-icon btn-nav-green btn btn-green flex-grow-1">
          <i class="fas fa-save"></i>
          Add
        </a>
        <a v-if="item.isInList" @click="removeItem" class="mobile-nav-icon btn-nav-red btn btn-red flex-grow-1">
          <i class="fas fa-save"></i>
          Remove
        </a>
      </template>
    </NavBar>
    <div v-cloak class="container home" v-if="item">
      <div class="row" v-cloak>
        <div class="col-12">
          <div class="row item-card">
            <div class="col-lg-2 col-12 text-center">
              <h2 class="d-block d-lg-none mb-1">
                <span>{{ item.title }}</span>
                <span class="year">({{ item.year }})</span>
              </h2>
              <img v-bind:src="item.poster" alt="" class="mb-3 mt-1" />

              <div class="d-md-block d-none">
                <a
                  target="_blank"
                  v-bind:href="'https://www.youtube.com/results?search_query=' + item.title + ' Trailer'"
                  class="btn-trailer btn btn-yellow w-100 mb-3"
                >
                  View Trailer
                </a>
                <a v-if="item.isInList != null && item.isInList" @click="removeItem" class="btn btn-red btn-add-movie w-100 mb-3">
                  <i class="fa fa-minus-circle" aria-hidden="true"></i>
                  Remove
                </a>
                <a v-if="item.isInList != null && !item.isInList" @click="addItem" class="btn btn-green btn-add-movie w-100 mb-3">
                  <i class="fa fa-plus-circle" aria-hidden="true"></i>
                  Add
                </a>
              </div>
              <div class="star-rating-container">
                <h5>Your Rating</h5>
                <div>
                  <i
                    @click="rateItem(index)"
                    v-for="index in 5"
                    :key="index"
                    :class="index <= item.userRating ? 'rate-selected' : ''"
                    class="fas fa-star item-rating-star"
                  ></i>
                </div>
              </div>
            </div>
            <div class="col-lg-10 col-12">
              <h2 class="d-none d-lg-block mb-1">
                <span>{{ item.title }}</span>
                <span class="year">({{ item.year }})</span>
              </h2>
              <p class="mb-1 mt-2">{{ item.plot }}</p>
              <p class="factfile">
                <b>Actors:&nbsp;</b>
                <span>{{ item.actor || "N/A" }}</span>
                <br />
                <b>Awards:&nbsp;</b>
                <span>{{ item.awards || "N/A" }}</span>
                <br />
                <b>BoxOffice:&nbsp;</b>
                <span>{{ item.boxOffice || "N/A" }}</span>
                <br />
                <b>Director:&nbsp;</b>
                <span>{{ item.director || "N/A" }}</span>
                <br />
                <b>DVD Release:&nbsp;</b>
                <span>{{ item.dvd || "N/A" }}</span>
                <br />
                <b>Genre:&nbsp;</b>
                <span>{{ item.genre || "N/A" }}</span>
                <br />
                <b>Production:&nbsp;</b>
                <span>{{ item.production || "N/A" }}</span>
                <br />
                <b>Rated:&nbsp;</b>
                <span>{{ item.rated || "N/A" }}</span>
                <br />
                <b>Released:&nbsp;</b>
                <span>{{ item.released || "N/A" }}</span>
                <br />
                <b>Writer:&nbsp;</b>
                <span>{{ item.writer || "N/A" }}</span>
                <br />
              </p>

              <small class="movie-ratings" v-for="rating in item.itemRatings" :key="rating">
                <b>{{ rating.ratingSource.ratingName }}</b>
                :
                <span>{{ rating.ratingValue }}</span>
                •
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import ItemSearch from "@/components/ItemSearch.vue";
import ItemService from "../services/ItemService";
import ListService from "../services/ListService";

export default {
  name: "Item",
  props: ["id"],
  data() {
    return {
      item: null
    };
  },
  computed: {
    listId: function() {
      return this.$route.query.listId;
    }
  },
  components: {
    NavBar,
    ItemSearch
  },
  methods: {
    addItem() {
      ListService.addItemToList(this.listId, this.item.id)
        .then(result => {
          if (result == "success") {
            this.item.isInList = !this.item.isInList;
            this.$toast.success("Added " + this.item.title);
          } else {
            this.$toast.console.error("Error adding " + this.item.title);
          }
        })
        .catch(error => {
          return error.handleError();
        });
    },
    removeItem() {
      ListService.removeItemFromList(this.listId, this.item.id)
        .then(result => {
          if (result == "success") {
            this.item.isInList = !this.item.isInList;
            this.$toast.success("Removed " + this.item.title);
          } else {
            this.$toast.console.error("Error removing " + this.item.title);
          }
        })
        .catch(error => {
          return error.handleError();
        });
    },
    rateItem(rating) {
      if (this.item.userRating == rating) {
        rating = 0;
      }

      ItemService.rateItem(this.item.id, rating)
        .then(() => {
          this.item.userRating = rating;

          if (rating == 0) {
            this.$toast.success("Rating removed");
          } else {
            this.$toast.success("Rated " + rating + " stars");
          }
        })
        .catch(error => {
          return error.handleError();
        });
    }
  },
  beforeRouteEnter(to, _from, next) {
    ItemService.getItem(to.query.listId, to.params.id)
      .then(item => {
        next(vm => (vm.item = item));
      })
      .catch(error => {
        return error.handleError();
      });
  },
  beforeRouteUpdate(to, _from, next) {
    this.item = null;

    ItemService.searchItems(to.query.listId, to.params.id)
      .then(item => {
        this.item = item;
        next();
      })
      .catch(error => {
        return error.handleError();
      });
  }
};
</script>

<style scoped>
.factfile b {
  color: #e8c547;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
}
i.fa-star.item-rating-star {
  font-size: 20px;
  color: #f5f6f4;
}

i.fa-star.item-rating-star.rate-selected {
  font-size: 20px;
  color: #e8c547 !important;
}

i.item-rating-star:hover {
  color: #e8c54a !important;
}

i.rate-selected.item-rating-star:hover {
  color: #ceaa28 !important;
}

.star-rating-container {
  text-align: center;
  margin-bottom: 10px;
}
</style>
