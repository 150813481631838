<template>
  <div>
    <NavBar>
      <template v-slot:desktop-nav></template>
      <template v-slot:mobile-nav>
        <router-link class="mobile-nav-icon btn-nav btn btn-yellow flex-grow-1" :to="'/'">
          <i class="fas fa-arrow-alt-circle-left"></i>
          Back
        </router-link>
      </template>
    </NavBar>
    <div v-cloak class="container home">
      <div class="movie-list-header">
        <h1>Predictions</h1>
      </div>

      <h4 class="text-center">
        The following predictions have been generate based on your rankings.
      </h4>

      <PredictionCardLarge v-for="prediction in predictions" :key="prediction" :prediction="prediction"></PredictionCardLarge>
      <div v-if="predictions.length == 0" class="d-flex justify-content-center mt-3">
        <div class="alert alert-warning text-center w-50 ">
          <h6 class="m-0">
            You have not ranked enough movies yet.
          </h6>
          <p class="m-0">
            Please rank at least 5 movies to generate predictions.
          </p>
        </div>
      </div>
      <!-- <div v-if="list.listItems.length == 0">
        <h2 class="text-center">
          No items found
          <i class="fas fa-frown"></i>
        </h2>
        <p class="text-center">Try Searching for your first item...</p>
      </div>

      <ItemCardLarge v-for="item in list.listItems" :key="item" :item="item.item" :listId="list.id" :dateAdded="item.createdAt"></ItemCardLarge> -->
    </div>
  </div>
</template>

<script>
import PredictionCardLarge from "@/components/PredictionCardLarge.vue";
import NavBar from "@/components/NavBar.vue";
import PredictionService from "./../services/PredictionService";
import { userId } from "../store/helpers";

export default {
  name: "Predictions",
  data() {
    return {
      predictions: []
    };
  },
  computed: {
    ...userId
  },
  components: {
    NavBar,
    PredictionCardLarge
  },
  mounted() {
    PredictionService.getPredictions()
      .then(predictions => {
        this.predictions = predictions;
      })
      .catch(error => {
        return error.handleError();
      });
  }
  // beforeRouteEnter(_to, _from, next) {
  //   ListService.getList(_to.params.id)
  //     .then(result => {
  //       next(vm => {
  //         vm.list = result.list;

  //         vm.userRole = result.userRole;
  //       });
  //     })
  //     .catch(error => {
  //       return error.handleError();
  //     });
  // }
};
</script>

<style scoped>
.search-box {
  border-radius: 0;
  color: #dedede;
  border: none;
  background-color: #6a72a0;
  background-image: url("https://www.transparenttextures.com/patterns/tweed.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  box-shadow: 5px 5px #4d5061;
  border: solid #4d5061;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  margin-right: 20px !important;
}

.search-box:hover {
  box-shadow: 5px 5px #c5a531;
  border-color: #e8c547;
}

.search-box:focus {
  background-color: #6a72a0;
  box-shadow: 0 0 0 0.2rem #c5a531;
  border-color: #e8c547;
  color: #e8c547;
}

.search-box::placeholder {
  color: #dedede;
}

.search-box::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
  content: "wefwe";

  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  /* background: red; */
}
</style>
