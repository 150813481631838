<template>
  <router-link :to="'/items/' + item.id + '?listId=' + listId" class="item-card item-card-action">
    <img class="mx-auto mb-lg-0 mb-3" :src="item.poster" alt="" />
    <div class="movie-item-body mb-sm-0 mb-3">
      <div class="flex">
        <h2 class="mb-1">
          {{ item.title }}
          <small class="year">({{ item.year }})</small>
        </h2>
      </div>
      <p class="mb-1 d-none d-sm-block">{{ item.plot }}</p>
      <p class="mb-1 d-block d-sm-none">{{ truncateText(100) }}</p>
      <div class="movie-ratings">
        <small v-for="rating in item.itemRatings" :key="rating">
          <b>{{ rating.ratingSource.ratingName }}</b>
          :
          <span>{{ rating.ratingValue }}</span>
          <span class="d-none d-md-inline">•</span>
          <div class="d-inline d-md-none"><br /></div>
        </small>
      </div>
    </div>
    <small class="date text-muted">added {{ timeAgo }}</small>
  </router-link>
</template>

<script>
import TimeAgo from "../utilities/TimeAgo";

export default {
  name: "ItemCardLarge",
  props: {
    item: Object,
    listId: Number,
    dateAdded: String
  },
  computed: {
    timeAgo() {
      return TimeAgo(this.dateAdded);
    }
  },
  methods: {
    truncateText(num) {
      if (this.item.plot.length > num) {
        let subString = this.item.plot.slice(0, num);
        return subString.substr(0, Math.min(subString.length, subString.lastIndexOf(" "))) + "...";
      } else {
        return this.item.plot;
      }
    }
  }
};
</script>

<style scoped>
.movie-item-body {
  margin-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.movie-ratings {
  margin-top: auto;
  margin-bottom: 5px;
}

.movie-ratings small b {
  color: #e8c547 !important;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .item-card small.date {
    left: 20px;
    bottom: 10px;
  }
}
</style>
