const generateRandomId = prefix => {
  return Math.random()
    .toString()
    .replace("0.", prefix + "-");
};

const generateErrorMessage = function(error) {
  switch (error.name) {
    case "FirebaseError":
      return generateFirebaseErrorMessage(error);
    default:
      console.warn("Unknown Error Occurred: ", error);
      return "Unknown Error Occurred";
  }
};

const generateFirebaseErrorMessage = function(error) {
  switch (error.code) {
    case "auth/wrong-password":
      return "Invalid Account Credentials";
    case "auth/user-not-found":
      return "Invalid Account Credentials";
    case "auth/invalid-email":
      return "Please enter a valid email address...";
    case "auth/too-many-requests":
      return "Too Many Login Attempts, Please try again later";
    default:
      console.warn("Firebase Error Occurred: ", error);
      return "Unknown Firebase Error Occurred";
  }
};

export { generateRandomId, generateErrorMessage };
