const Role = {
  OWNER: [1],
  ADMIN: [1, 2],
  MEMBER: [1, 2, 3],
  GUEST: [1, 2, 3, 4]
};

const hasRole = (roleId, role) => {
  return role.includes(roleId);
};

export { Role, hasRole };
