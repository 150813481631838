<template>
  <div id="main">
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import axios from "axios";
import { auth } from "./store/auth";

export default {
  components: {
    Footer
  },
  created() {
    const errorComposer = error => {
      return () => {
        console.error(error);

        const statusCode = error.response ? error.response.status : null;

        if (statusCode != null) {
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
          }

          if (error.response.data.status == "fail") {
            this.$toast.warning(error.response.data.message);
          } else if (error.response.data.status == "error") {
            this.$toast.error(error.response.data.message);
          } else {
            this.$toast.error("An unknown error occured");
          }
        } else {
          this.$toast.error("No connection!<br>Check your connection settings.");
        }
      };
    };

    axios.interceptors.response.use(
      response => response,
      error => {
        error.handleError = errorComposer(error);
        return Promise.reject(error);
      }
    );

    axios.interceptors.request.use(
      async config => {
        if (auth.currentUser != null) {
          let token = await auth.currentUser.getIdToken();
          config.headers.authorization = "Bearer " + token;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.withCredentials = true;

    try {
      const userJSON = localStorage.getItem("user");

      if (userJSON) {
        const userData = JSON.parse(userJSON);

        this.$store.commit("SET_USER_DATA", userData);
      }
    } catch (error) {
      this.$toast.error("An error occurred while retrieving user information");

      this.$store.dispatch("logout");
    }
  }
};
</script>

<style>
/*                    */
/*    GLOBAL RULES    */
/*                    */
.c-toast-container .c-toast {
  border: solid 3px;
  border-radius: 0 !important;
  max-width: 400px;
  display: flex !important;
  padding-left: 0px !important;
}

.c-toast-container .c-toast::before {
  padding: 0 16px;
  background: none !important;
}

.c-toast-container .c-toast.c-toast--success {
  color: #f5f6f4;
  background-color: rgba(119, 182, 82, 0.8);
  border-color: #5a912f;
}

.c-toast-container .c-toast.c-toast--success::before {
  background-color: rgba(119, 182, 82, 0.8);
  border-color: #5a912f;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f058";
  font-size: 20px;
}

.c-toast-container .c-toast.c-toast--error::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f06a";
  font-size: 20px;
}

.c-toast-container .c-toast.c-toast--default {
  color: #f5f6f4;
  background-color: rgba(106, 114, 160, 0.8);
  border-color: #393d55;
}

.c-toast-container .c-toast.c-toast--error {
  color: #f5f6f4;
  background-color: rgba(232, 71, 71, 0.8);
  border: solid #c53131;
}

.c-toast-container .c-toast.c-toast--warning {
  color: #3b310d;
  background-color: rgba(232, 197, 71, 0.8);
  border: solid #c5a531;
}

.c-toast-container .c-toast.c-toast--warning::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f071";
  font-size: 20px;
}

body {
  background-color: #4d5061;
  background-image: url("https://www.transparenttextures.com/patterns/45-degree-fabric-dark.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

.item-card,
.item-card:active,
.item-card:focus {
  display: flex;
  background-color: #6a72a0;
  background-image: url("https://www.transparenttextures.com/patterns/tweed.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  border: solid #393d55 5px !important;
  border-radius: 0 !important;
  position: relative;
}

.is-invalid {
  color: #dc3545 !important;
}

.form-control {
  border-radius: 0;
  border: solid #393d55;
}

.form-control:focus {
  border-radius: 0;
  border: solid #e8c547;
  box-shadow: none;
}

.invalid-feedback {
  color: #f5f6f4;
}

.flex-col {
  flex-direction: column;
}

.flex {
  display: flex;
}

/* Removes firefox border */
a:focus {
  outline: 0;
}

a:hover,
a.nav-link:hover,
.navbar-brand i:hover {
  color: #e8c547;
  text-decoration: none;
}

.navbar-toggler-icon {
  font-size: 30px;
  display: inline;
}

/* Buttons */
.btn {
  border-radius: 0;
}

button.btn:active,
a.btn:active {
  box-shadow: none !important;
}

.btn-yellow {
  color: #30323d !important;
  background-color: #e8c547;
  border: solid #c5a531;
}

.btn-yellow:hover {
  color: #30323d;
  background-color: #c5a531;
  border: solid #a3892a;
}

.btn-red {
  color: #f5f6f4 !important;
  background-color: #e84747;
  border: solid #c53131;
}

.btn-red:hover {
  color: #d4d4d4 !important;
  background-color: #c53131;
  border: solid #b12b2b;
}

.btn-blue {
  color: #f5f6f4;
  background-color: #3c99bd;
  border: solid #25809d;
}

.btn-green {
  color: #f5f6f4 !important;
  background-color: #77b652;
  border: solid #5a912f;
}

.btn-green:hover {
  color: #d4d4d4 !important;
  background-color: #5a912f;
  border: solid #50812a;
}

.btn-grey {
  color: #f5f6f4;
  border-radius: 0;
  background-color: #969696;
  border: solid #808080;
}

.btn i {
  vertical-align: initial;
}

.btn-outline-success {
  border-color: #e8c547;
  color: #e8c547;
}

.btn-outline-success:hover {
  border-color: #c5a531;
  border-width: 2px;
  background-color: #e8c547;
}

.btn-outline-success:not(:disabled):not(.disabled):active {
  border-color: #c5a531;
  border-width: 2px;
  background-color: #c5a531;
}

.btn-outline-success:focus,
.btn-outline-success:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 197, 71, 0.5) !important;
}

.btn-nav {
  box-shadow: 3px 3px #c5a531;
  font-size: 18px;
  font-weight: bold;
}

.btn-nav-green {
  font-size: 18px;
  box-shadow: 3px 3px #5a912f;
}

.btn-nav-red {
  font-size: 18px;
  box-shadow: 3px 3px #c53131;
}

.btn-nav:hover {
  box-shadow: 3px 3px #a3892a;
}

.btn-nav i {
  vertical-align: baseline;
}

.search-box {
  border-radius: 0;
  color: #dedede;
  border: none;
  background-color: #6a72a0;
  background-image: url("https://www.transparenttextures.com/patterns/tweed.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  box-shadow: 5px 5px #4d5061;
  border: solid #4d5061;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  margin-right: 20px !important;
  flex-grow: 1;
  width: auto !important;
}

.search-box:hover {
  box-shadow: 5px 5px #c5a531;
  border-color: #e8c547;
}

.search-box:focus {
  background-color: #6a72a0;
  box-shadow: 0 0 0 0.2rem #c5a531;
  border-color: #e8c547;
  color: #e8c547;
}

.search-box::placeholder {
  color: #dedede;
}

.search-box::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
  content: "wefwe";

  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  /* background: red; */
}

a,
p {
  color: #f5f6f4;
}

span,
small {
  color: #f5f6f4;
}

.alert-warning p,
.alert-warning h5 {
  color: #856404;
}

p,
footer {
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
.navbar a {
  color: #f5f6f4;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}

h1 {
  font-size: 60px;
  text-align: center;
}

.alert:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 14px;
  left: 14px;
  background-color: #e8c5477c;
}

.alert {
  background-color: #e8c547;
  border: solid #c5a531 5px;
  border-radius: 0;
}

.alert a:hover {
  color: #000;
}

.movie-ratings {
  color: #e8c547;
}

.movie-list-header {
  margin: 20px;
}

.mediaquerybox {
  width: 100px;
  height: 100px;
  background-color: violet;
  position: absolute;
}

.mobile {
  display: none;
}

[v-cloak] {
  display: none;
}

.fa {
  vertical-align: middle;
}

.svg {
  background-size: contain;
  height: 500px;
  width: 500px;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;
}
.text-muted {
  color: #c6c6c6 !important;
}

/*                    */
/*    Movie Card      */
/*                    */
.item-card img {
  height: 200px;
  width: 133px;
  object-fit: cover;
}

.image-search-link {
  height: 200px;
}

.list-thumbnails {
  margin-left: 30px;
}

.item-card,
.item-card:active,
.item-card:focus {
  display: flex;
  background-color: #6a72a0;
  background-image: url("https://www.transparenttextures.com/patterns/tweed.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  border: solid #393d55 5px !important;
  border-radius: 0 !important;
  margin: 20px 10px;
  position: relative;
  padding: 0.75rem 1.25rem;
}

.modal-content.item-card:before {
  display: none;
}

.item-card-action:hover {
  background-color: rgb(121, 131, 182);
  border: solid #e8c547 5px !important;
  z-index: inherit;
}

a.item-card:hover:before {
  /* background-color: #323755; */
  background-color: #e8c5477c;
}

.item-card:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 14px;
  left: 14px;
  background-color: #393d55;
  background-image: url("https://www.transparenttextures.com/patterns/tweed.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.item-card small.date {
  position: absolute;
  right: 20px;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-between;
}

.mobile-nav-icon {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  padding: 6px 0 8px 0;
}

.mobile-nav-icon i {
  font-size: 20px;
  margin-bottom: 2px;
}

.modal-body {
  color: #fff;
}

/* Progress Bar */

#nprogress .bar {
  background: rgba(232, 197, 71, 0.7);

  height: 2px;
}

#nprogress .peg {
  box-shadow: 0 0 10px rgba(232, 197, 71, 1), 0 0 5px rgba(232, 197, 71, 1);
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .item-card img {
    height: 130px;
    width: 86.45px;
    object-fit: cover;
  }

  h4 {
    font-size: 20px;
  }

  p {
    font-size: 13px;
  }

  .alert {
    padding: 0.5rem 0.75rem;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}
</style>
