<template>
  <div class="container login text-center">
    <div>
      <div class="movie-list-header mt-0">
        <h1>Please sign in</h1>
      </div>
      <LoginForm></LoginForm>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
import { authComputed } from "../../store/helpers.js";

export default {
  name: "Login",
  components: {
    LoginForm
  },
  computed: {
    ...authComputed
  }
};
</script>

<style scoped>
.login {
  height: calc(100vh - 136px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
