import axios from "axios";
import { auth } from "../store/auth";
import { sendPasswordResetEmail, updateProfile, updateEmail, sendEmailVerification } from "firebase/auth";

export default {
  authenticate(email, password) {
    return axios
      .post("/auth", {
        email: email,
        password: password
      })
      .then(response => {
        return response.data.data.user;
      });
  },

  logout() {
    return axios.post("/logout");
  },

  saveDetails(displayName, email) {
    return updateProfile(auth.currentUser, {
      displayName: displayName,
      photoURL: "https://example.com/jane-q-user/profile.jpg"
    }).then(updateEmail(auth.currentUser, email));
  },

  deleteAccount(userId) {
    return axios.delete("/users/" + userId);
  },

  forgotPassword(email) {
    return sendPasswordResetEmail(auth, email);
  },

  canResetPassword(email, token) {
    return axios.get("/users/resetPassword", {
      params: {
        email,
        token
      }
    });
  },

  resetPassword(email, token, newPassword) {
    return axios.post("/users/resetPassword", {
      email,
      token,
      newPassword
    });
  },

  resendVerificationEmail() {
    return sendEmailVerification(auth.currentUser);
  },

  verifyEmailAddress(email, token) {
    return axios.post("/users/verify", {
      email,
      token
    });
  }
};
