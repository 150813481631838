<template>
  <div id="ListSettings">
    <div class="modal fade confirmation-modal" id="list-create-modal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content item-card" v-if="listCopy">
          <div class="modal-header">
            <h2 class="modal-title">
              {{ createMode ? "Create" : "Edit" }}
              {{ listCopy.listName }} List
            </h2>
          </div>
          <div class="modal-body">
            <form class="create-list container-fluid">
              <div class="form-group row">
                <label for="listName" class="col-sm-3 col-form-label">Name</label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    id="listName"
                    :class="{ 'is-invalid': v$.listCopy.listName.$error }"
                    placeholder="e.g. Cinema Movies..."
                    v-model="listCopy.listName"
                  />
                  <div class="invalid-feedback" v-if="v$.listCopy.listName.$error">
                    {{ v$.listCopy.listName.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="form-group row" v-if="createMode">
                <label for="listType" class="col-sm-3 col-form-label">List Type</label>
                <div class="col-sm-9">
                  <select class="form-control" v-model="listCopy.listTypeId">
                    <option v-for="type in listTypes" :key="type" :value="type.id">{{ type.typeName }}</option>
                  </select>
                  <small id="passwordHelpBlock" class="form-text">
                    This can't be changed later.
                  </small>
                </div>
              </div>

              <div class="form-group row">
                <label for="description" class="col-sm-4 col-form-label">Description</label>
                <div class="col-sm-12">
                  <textarea
                    class="form-control"
                    id="description"
                    placeholder="e.g. A list of upcoming cinema movies"
                    rows="4"
                    cols="50"
                    v-model="listCopy.description"
                    :class="{ 'is-invalid': v$.listCopy.description.$error }"
                  ></textarea>
                  <div class="invalid-feedback" v-if="v$.listCopy.description.$error">
                    {{ v$.listCopy.description.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </form>
            <div class="confirmation-buttons">
              <div>
                <a v-if="hasRole(Role.OWNER) && !createMode" class="btn btn-red" @click="confirmDelete">Delete</a>
                <a v-if="!hasRole(Role.OWNER)" class="btn btn-red" @click="leaveGroup">Leave</a>
              </div>
              <div>
                <a class="btn btn-green" v-if="createMode" @click="createList">Create</a>
                <a class="btn btn-green" v-else @click="saveList">Save</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteConfirm @delete-confirmed="deleteList" @closed="openConfirm = false" :value="listCopy.listName" v-if="listCopy" :open="openConfirm" />
    <DeleteConfirm
      @delete-confirmed="leaveListConfirm"
      @closed="openLeaveConfirm = false"
      :value="list.listName"
      :title="'Leave ' + list.listName"
      text="Please retype the name in order to leave"
      buttonText="Leave"
      :open="openLeaveConfirm"
    />
  </div>
</template>

<script>
import ListService from "../services/ListService";
import DeleteConfirm from "./DeleteConfirm";
import { computed } from "vue";
import { Role, hasRole } from "../utilities/list.role";
import { userId } from "../store/helpers";
import $ from "jquery";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "listSettings",
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    list: Object,
    userRole: null,
    createMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...userId
  },
  components: {
    DeleteConfirm
  },
  emits: ["created"],
  provide() {
    return {
      userRole: computed(() => this.userRole)
    };
  },
  data() {
    return {
      listObject: this.list,
      listCopy: null,
      openConfirm: false,
      listTypes: null,
      Role,
      openLeaveConfirm: false
    };
  },
  validations() {
    return {
      listCopy: {
        listName: { required },
        description: { required }
      }
    };
  },
  methods: {
    hasRole(role) {
      if (this.createMode) {
        return true;
      }

      return hasRole(this.userRole.id, role);
    },
    leaveGroup() {
      $("#list-create-modal").modal("hide");
      this.openLeaveConfirm = true;
    },
    leaveListConfirm() {
      ListService.deleteUser(this.list.id, this.userId)
        .then(() => {
          this.$toast.success("Successfully Left " + this.list.listName);

          this.$router.push({ path: "/lists" });
        })
        .catch(error => {
          return error.handleError();
        });
    },
    saveList() {
      ListService.saveList(this.listCopy)
        .then(() => {
          Object.assign(this.listObject, this.listCopy);

          this.$toast.success("Successfully Saved List");
        })
        .catch(error => {
          return error.handleError();
        });
      $("#list-create-modal").modal("hide");
    },

    async createList() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      ListService.createList(this.listCopy)
        .then(list => {
          Object.assign(this.listObject, this.listCopy);

          this.$toast.success("Successfully Created List");

          this.$emit("created", list);
        })
        .catch(error => {
          return error.handleError();
        });
      $("#list-create-modal").modal("hide");
    },

    confirmDelete() {
      $("#list-create-modal").modal("hide");

      this.openConfirm = true;
    },
    deleteList() {
      ListService.deleteList(this.listCopy.id)
        .then(result => {
          $("#delete-confirm").modal("hide");

          if (result == "success") {
            this.$toast.success("Successfully Deleted List");

            this.$router.push({ path: "/lists" });
          } else {
            this.$toast.error("Unable to delete list");
          }
        })
        .catch(error => {
          return error.handleError();
        });
    }
  },
  mounted() {
    ListService.getAllTypes()
      .then(types => {
        this.listTypes = types;
      })
      .catch(error => {
        return error.handleError();
      });

    $("#list-create-modal").on("show.bs.modal", () => {
      // Making a copy of the object
      this.listCopy = JSON.parse(JSON.stringify(this.listObject));
    });
  }
};
</script>

<style scoped></style>
