<template>
  <div class="modal fade confirmation-modal" id="add-user" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content item-card">
        <div class="modal-header">
          <h2 class="modal-title">Add a user</h2>
        </div>
        <div class="modal-body">
          <label for="email" class="sr-only">Email address</label>
          <input type="email" id="email" name="email" class="form-control" placeholder="Email address" required autofocus v-model="userEmail" />
          <div class="invalid-feedback">
            Invalid Email.
          </div>
        </div>
        <div class="modal-footer">
          <a class="btn btn-green" @click="addUser">Add User</a>
          <a class="btn btn-red" @click="close">Cancel</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "AddUser",
  emits: ["add-user"],
  data() {
    return {
      userEmail: ""
    };
  },
  methods: {
    close() {
      $("#add-user").modal("hide");
    },
    addUser() {
      let regex = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

      if (!regex.test(this.userEmail)) {
        $("#email").addClass("is-invalid");
      } else {
        $("#email").removeClass("is-invalid");

        $("#add-user").modal("hide");

        this.$emit("add-user", this.userEmail);
      }
    }
  },
  mounted() {
    var vm = this;

    $("#add-user").on("show.bs.modal", function() {
      vm.userEmail = "";
    });
  }
};
</script>

<style scoped></style>
