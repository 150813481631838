<template>
  <div class="item-card">
    <router-link class="image-search-link" :to="'/items/' + item.imdbID + '?listId=' + listId">
      <img v-bind:src="item.Poster" class="float-left" />
    </router-link>
    <div class="search-item-body">
      <h3 class="mb-1">{{ item.Title }}</h3>
      <span>{{ item.Year }}</span>
      <div class="search-action-buttons">
        <router-link :to="'/items/' + item.imdbID + '?listId=' + listId" class="btn btn-yellow btn-view-movie">
          <i class="fa fa-search" aria-hidden="true"></i>
        </router-link>
        <a v-if="item.isInList" @click="removeItem" class="btn btn-red btn-add-movie"><i class="fa fa-minus-circle" aria-hidden="true"></i></a>
        <a v-if="!item.isInList" @click="addItem" class="btn btn-green btn-add-movie"><i class="fa fa-plus-circle" aria-hidden="true"></i></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchItem",
  props: ["item", "listId"],
  emits: ["add-item", "remove-item"],
  methods: {
    addItem() {
      this.$emit("add-item", this.item);
    },
    removeItem() {
      this.$emit("remove-item", this.item);
    }
  }
};
</script>

<style scoped>
.movie-item-body {
  margin-left: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-action-buttons {
  display: flex;
  margin: auto 0 0 0;
}

.search-item-body {
  color: white;
  bottom: 12px;
}

.search-item-body {
  margin-left: 20px;
  flex-direction: column;
  display: flex;
  width: 100%;
}

.search-group .item-card h3 {
  font-size: 20px;
}

.search-item-body a.btn {
  width: 100%;
  height: 45px;
  border-radius: 0;
  margin: 5px;
  font-size: 20px;
}
</style>
