import { mapGetters } from "vuex";

const authComputed = {
  ...mapGetters(["loggedIn"])
};

const userId = {
  ...mapGetters(["userId"])
};

const email = {
  ...mapGetters(["email"])
};

const displayName = {
  ...mapGetters(["displayName"])
};

const emailVerified = {
  ...mapGetters(["emailVerified"])
};

export { authComputed, userId, email, displayName, emailVerified };
