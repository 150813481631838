<template>
  <div class="modal fade confirmation-modal" :id="modalId" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content item-card">
        <div class="modal-header">
          <h2 class="modal-title">Password Reset</h2>
        </div>
        <div class="modal-body">
          <form class="create-list container-fluid">
            <div class="form-group row">
              <label for="deleteConfirmInput">Email Address</label>
              <input type="text" class="form-control" id="forgotPasswordEmailInput" v-model="userInput" placeholder="leon@example.com" />
              <div class="invalid-feedback">
                This is not a valid email address.
              </div>
            </div>
          </form>
          <div class="confirmation-buttons">
            <a class="btn btn-red" @click="close">Cancel</a>
            <a class="btn btn-green" @click="forgotPassword">Request Password Reset</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import AccountService from "../services/AccountService";
import { generateRandomId } from "../utilities/utilities";

export default {
  name: "ForgotPasswordModal",
  props: ["open"],
  watch: {
    open: function(val) {
      if (val) {
        this.userInput = "";
        $(`#${this.modalId}`).modal("show");
      } else {
        $(`#${this.modalId}`).modal("hide");
      }
    }
  },
  emits: ["closed"],
  data() {
    return {
      userInput: "",
      modalId: generateRandomId("Forogot-Password-Modal")
    };
  },
  methods: {
    forgotPassword() {
      // Ensuring the string is a valid email address
      if (!/^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(this.userInput)) {
        $(`#${this.modalId} #forgotPasswordEmailInput`).addClass("is-invalid");
      } else {
        AccountService.forgotPassword(this.userInput)
          .then(() => {
            this.$toast.success("Password reset requested.<br>If an account is found with the email address provided, you will receive a password reset email");
            $(`#${this.modalId}`).modal("hide");
          })
          .catch(error => {
            return error.handleError();
          });
      }
    },
    close() {
      $(`#${this.modalId}`).modal("hide");
    }
  },
  mounted() {
    var self = this;

    if (this.open) {
      $(`#${this.modalId}`).modal("show");
    }

    $(`#${this.modalId}`).on("hide.bs.modal", function() {
      self.$emit("closed");
    });
  }
};
</script>

<style scoped></style>
