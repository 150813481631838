<template>
  <div id="user-management-container">
    <div class="modal fade confirmation-modal" id="user-management" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog  modal-lg modal-dialog-centered" role="document">
        <div class="modal-content item-card">
          <div class="modal-header">
            <h2 class="modal-title">User Management</h2>
          </div>
          <div class="modal-body">
            <div v-if="open" class="row mt-3 mb-3 justify-content-md-center">
              <div class="col-3" v-for="user in users" :key="user">
                <UserCard :user="user" :selectableRoles="selectableRoles" @save-user="saveUser" @delete-user="confirmDeleteUser" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a class="btn btn-green" data-toggle="modal" data-target="#add-user">Add User</a>
            <a class="btn btn-red" data-dismiss="modal">Close</a>
          </div>
        </div>
      </div>
    </div>
    <UserAdd @add-user="addUser" />
    <DeleteConfirm
      v-if="deleteConfirm.user"
      @delete-confirmed="deleteUser"
      :value="deleteConfirm.user.displayName"
      :open="deleteConfirm.open"
      @closed="deleteConfirm.open = false"
    />
  </div>
</template>

<script>
import UserCard from "./UserCard";
import ListService from "../../services/ListService";
import { Role, hasRole } from "../../utilities/list.role";
import DeleteConfirm from "../../components/DeleteConfirm";
import UserAdd from "./UserAdd";
import $ from "jquery";

export default {
  name: "UserManagement",
  props: ["id"],
  data() {
    return {
      users: [],
      selectableRoles: [],
      deleteConfirm: {
        open: false,
        user: null
      },
      open: false
    };
  },
  inject: ["userRole"],
  components: {
    UserCard,
    DeleteConfirm,
    UserAdd
  },
  mounted() {
    ListService.getUsers(this.id)
      .then(result => {
        this.users = result.users;
      })
      .catch(error => {
        return error.handleError();
      });

    if (hasRole(this.userRole.value.id, Role.OWNER)) {
      this.selectableRoles.push({
        id: 2,
        roleName: "Admin"
      });
    }

    if (hasRole(this.userRole.value.id, Role.ADMIN)) {
      this.selectableRoles.push({
        id: 3,
        roleName: "Member"
      });
      this.selectableRoles.push({
        id: 4,
        roleName: "Guest"
      });
    }

    var vm = this;

    $("#user-management").on("show.bs.modal", function() {
      vm.open = true;
    });

    $("#user-management").on("hidden.bs.modal", function() {
      vm.open = false;
    });
  },
  methods: {
    saveUser(userId, roleId) {
      ListService.saveUser(this.id, userId, roleId)
        .then(() => {
          this.$toast.success("Successfully Saved User");
        })
        .catch(error => {
          return error.handleError();
        });
    },
    confirmDeleteUser(user) {
      this.deleteConfirm.user = user;
      this.deleteConfirm.open = true;
    },
    deleteUser() {
      var vm = this;

      ListService.deleteUser(this.id, this.deleteConfirm.user.id)
        .then(() => {
          this.$toast.success("Successfully Deleted User");
          vm.users = vm.users.filter(user => user.id != vm.deleteConfirm.user.id);
        })
        .catch(error => {
          return error.handleError();
        });
    },
    addUser(email) {
      var vm = this;

      ListService.addUser(this.id, email)
        .then(result => {
          vm.users.push(result.user);

          this.$toast.success(`Successfully Added ${email}`);
        })
        .catch(error => {
          return error.handleError();
        });
    }
  }
};
</script>

<style scoped>
.user-card .user-icon {
  font-size: 70px;
}

.user-card h5 {
  font-size: 20px;
  margin: 0;
}

.user-card .edit-icon {
  display: none;
}

.user-card:hover .edit-icon {
  position: absolute;
  right: 20px;
  color: lightgray;
  display: inline-block;
}

.user-card:hover .edit-icon:hover {
  color: whitesmoke;
}
</style>
