import { createRouter, createWebHistory } from "vue-router";
import Lists from "../views/Lists.vue";
import List from "../views/List";
import Order from "../views/Order.vue";
import Items from "../views/Items.vue";
import Item from "../views/Item.vue";
import Login from "../views/Login";
import PasswordReset from "../views/PasswordReset";
import Verify from "../views/Verify";
import Predictions from "../views/Predictions";
import NProgress from "nprogress";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Lists,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/lists",
    name: "Lists",
    component: Lists,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/lists/:id",
    name: "List",
    component: List,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/lists/:id/order",
    name: "Order",
    component: Order,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/items",
    name: "Items",
    component: Items,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/items/:id",
    name: "Item",
    component: Item,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/passwordReset",
    name: "Password Reset",
    component: PasswordReset
  },
  {
    path: "/verify",
    name: "Verify Email Address",
    component: Verify
  },
  {
    path: "/predictions",
    name: "Predictions",
    component: Predictions,
    meta: {
      requiresAuth: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  var loggedIn = store.getters["loggedIn"];

  if (to.name) {
    NProgress.start();
  }

  if (to.matched.some(record => record.meta.requiresAuth && !loggedIn)) {
    next("/login");
  }

  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
