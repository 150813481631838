<template>
  <div>
    <NavBar>
      <template v-slot:desktop-nav>
        <div class="d-flex" v-if="list">
          <ItemSearch :listId="id" />
          <router-link class="btn-nav btn btn-yellow my-2 my-sm-0 ml-2" :to="'/lists/' + list.id">
            <i class="fas fa-arrow-alt-circle-left mr-1"></i>
            Back
          </router-link>
          <a class="btn-nav-green btn btn-green my-2 my-sm-0 ml-2" @click="save"><i class="fas fa-save"></i></a>
        </div>
      </template>
      <template v-slot:mobile-nav>
        <router-link class="mobile-nav-icon btn-nav btn btn-yellow flex-grow-1" :to="'/lists/' + list.id" v-if="list">
          <i class="fas fa-arrow-alt-circle-left"></i>
          Back
        </router-link>
        <div class="mobile-nav-icon btn-nav-green btn btn-green list-save-button" @click="save">
          <i class="fas fa-save"></i>
          Save
        </div>
      </template>
    </NavBar>
    <div class="container home">
      <div class="movie-list-header">
        <div v-cloak v-if="list">
          <h1>{{ list.listTypeId == listType.RANKED ? "Rank" : "Order" }} {{ list.listName }} List</h1>
        </div>
      </div>

      <div v-if="unRankedItems && unRankedItems.length != 0">
        <h3>Unranked</h3>
        <draggable
          v-if="unRankedItems"
          v-model="unRankedItems"
          handle=".sort-icon"
          item-key="id"
          class="list-group"
          id="unranked-group"
          :group="{ name: 'listItems', pull: true, put: false }"
          v-bind="dragOptions"
        >
          <template #item="{element, index}">
            <div class="item-card item-sorting item item-card-action align-items-center flex-column flex-sm-row">
              <div class="d-flex">
                <div class="sort-icon mr-3">
                  <i class="fas fa-sort-up" :class="{ disabled: index == 0 }" @click="moveUnRankedUp(element, index)"></i>
                  <h2 class="sorting-index-na">N/A</h2>
                  <i class="fas fa-sort-down" @click="moveUnRankedDown(element, index)"></i>
                </div>
                <img class="float-left" v-bind:src="element.poster" alt="" />
              </div>
              <div class="movie-item-body">
                <div class="flex">
                  <h2 class="mb-1">
                    {{ element.title }}
                    <span class="year">({{ element.year }})</span>
                  </h2>
                </div>
                <p class="mb-1">{{ element.plot }}</p>
                <small v-for="rating in element.itemRatings" :key="rating">
                  <b>{{ rating.ratingSource.ratingName }}</b>
                  :
                  <span>{{ rating.ratingValue }}</span>
                  •
                </small>
              </div>
              <small class="date">{{ element.addedWhen }}</small>
            </div>
          </template>
        </draggable>
        <hr />
      </div>

      <h3>Ranked</h3>
      <draggable
        v-model="rankedItems"
        handle=".sort-icon"
        item-key="id"
        v-bind="dragOptions"
        :group="{ name: 'listItems', pull: false, put: true }"
        :move="itemMoved"
      >
        <template #header>
          <div class="item-sorting item-template" v-if="unRankedItems && unRankedItems.length != 0">
            <h2 class="">Drag items here to rank</h2>
          </div>

          <div v-if="rankedItems && rankedItems.length == 0 && unRankedItems && unRankedItems.length == 0">
            <h2 class="text-center">
              No items found
              <i class="fas fa-frown"></i>
            </h2>
            <p class="text-center">Try Searching for your first item...</p>
          </div>
        </template>
        <template #item="{element, index}">
          <div>
            <div class="item-card item-sorting item-card-action align-items-center flex-column flex-sm-row">
              <div class="d-flex">
                <div class="sort-icon mr-3">
                  <i class="fas fa-sort-up" :class="{ disabled: index == 0 }" @click="moveUp(element, index)"></i>
                  <h2 class="">{{ getScore(element) }}</h2>
                  <i class="fas fa-sort-down" :class="{ disabled: index == rankedItems.length - 1 }" @click="moveDown(element, index)"></i>
                </div>
                <img class="float-left" v-bind:src="element.poster" alt="" />
              </div>
              <div class="movie-item-body">
                <div class="flex">
                  <h2 class="mb-1">
                    {{ element.title }}
                    <span class="year">({{ element.year }})</span>
                  </h2>
                </div>
                <p class="mb-1">{{ element.plot }}</p>
                <small v-for="rating in element.itemRatings" :key="rating">
                  <b>{{ rating.ratingSource.ratingName }}</b>
                  :
                  <span>{{ rating.ratingValue }}</span>
                  •
                </small>
              </div>
              <!-- <small class="date">{{ element.addedWhen }}</small> -->
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import ListService from "../services/ListService";
import draggable from "vuedraggable";
import ItemSearch from "@/components/ItemSearch.vue";
import ListType from "../utilities/listTypes";

export default {
  name: "OrderList",
  props: ["id"],
  data() {
    return {
      list: null,
      unRankedItems: null,
      rankedItems: null,
      listType: ListType
    };
  },
  components: {
    NavBar,
    draggable,
    ItemSearch
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        dragClass: "dragging"
      };
    }
  },
  methods: {
    moveUnRankedUp(item) {
      let index = this.unRankedItems.indexOf(item);

      if (index != 0) {
        var itemObj = this.unRankedItems.splice(index, 1)[0];

        this.unRankedItems.splice(index - 1, 0, itemObj);
      }
    },
    moveUnRankedDown(item) {
      let index = this.unRankedItems.indexOf(item);

      var itemObj = this.unRankedItems.splice(index, 1)[0];

      if (index < this.unRankedItems.length) {
        this.unRankedItems.splice(index + 1, 0, itemObj);
      } else {
        // Move to ranked list
        this.rankedItems.splice(0, 0, itemObj);
      }
    },
    moveUp(item) {
      let index = this.rankedItems.indexOf(item);

      if (index != 0) {
        var itemObj = this.rankedItems.splice(index, 1)[0];

        this.rankedItems.splice(index - 1, 0, itemObj);
      }
    },
    moveDown(item) {
      let index = this.rankedItems.indexOf(item);

      if (index < this.rankedItems.length - 1) {
        var itemObj = this.rankedItems.splice(index, 1)[0];

        this.rankedItems.splice(index + 1, 0, itemObj);
      }
    },
    save() {
      ListService.saveRankedItems(this.list.id, this.rankedItems)
        .then(result => {
          if (result == "success") {
            this.$toast.success("Successfully Saved Rankings");
          } else {
            this.$toast.error("Unable To Save Ranked List");
          }
        })
        .catch(error => {
          return error.handleError();
        });
    },
    itemMoved(evt) {
      var originalScore = evt.relatedContext.element.score;

      evt.relatedContext.element.score = evt.draggedContext.element.score;

      evt.draggedContext.element.score = originalScore;
    },
    getScore(element) {
      return this.rankedItems.indexOf(element) + 1;
    }
  },
  beforeRouteEnter(_to, _from, next) {
    Promise.all([ListService.getList(_to.params.id), ListService.getRankedList(_to.params.id)])
      .then(results => {
        next(vm => {
          vm.list = results[0].list;
          vm.rankedItems = results[1].rankedItems;
          vm.unRankedItems = results[1].unRankedItems;
        });
      })
      .catch(error => {
        return error.handleError();
      });
  }
};
</script>

<style scoped>
.sortable-chosen.item-card-action {
  background-color: rgb(121, 131, 182);
  border: solid #e8c547 5px !important;
  z-index: inherit;
}

.sortable-chosen {
  opacity: 1;
}

.ghost.item-card-action {
  background-color: rgb(121, 131, 182);
  border: solid #e8c547 5px !important;
  z-index: inherit;
}

.item-card:before {
  background: none;
}

.movie-item-body {
  margin-left: 20px;
  width: 100%;
}

.movie-ratings small b {
  color: #e8c547 !important;
}

.item-sorting .fa,
.item-sorting .sort-icon {
  font-size: 50px;
  align-self: center;
}

.item-sorting .sort-icon .disabled {
  color: #2125284a;
}

.item-sorting .sort-icon {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 79px;
}

.item-sorting .sort-icon:hover {
  color: #e8c547 !important;
  cursor: grab;
}

.item-sorting .sort-icon > .fas {
  height: 20px;
}

.item-sorting .sort-icon > h2 {
  margin: 0;
  line-height: 1;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
  height: 28px;
  margin-top: 5px;
}

.item-sorting .fa-sort-down {
  line-height: 0;
}

.list-save-button {
  flex-grow: 4;
}

.item-sorting img {
  width: 90px;
  height: 157px;
}

.item-sorting {
}

.item-sorting .fa-sort:hover {
  cursor: grab;
}

.item-sorting:hover {
  border: solid #393d55 5px !important;
}

.fa-sort:hover {
  color: #e8c547 !important;
}

.draggable-mirror {
  outline: -webkit-focus-ring-color auto 1px;
}

.sorting-index,
.sorting-index-na {
  align-self: center;
  margin-right: 20px;
  width: 20px;
  line-height: 2 !important;
  transform: translateX(-20%);
  font-size: 1rem;
}

#unranked-group .item-sorting {
  background-color: #898989;
}

.item-template {
  height: 157px;
  border: 5px #80808080 dashed;
  color: #80808080;
  background-color: #8080800d;
  display: flex;
}

.item-template h2 {
  vertical-align: middle;
  margin: auto;
}

@media (max-width: 991.98px) {
  .item-sorting .sort-icon > h2 {
    line-height: 1.4;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .sort-icon {
    position: absolute;
    left: 40px;
  }
}
</style>
