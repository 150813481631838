import axios from "axios";

export default {
  getAllLists() {
    return axios.get("/lists").then(response => {
      return response.data.data.lists;
    });
  },

  getList(listID) {
    return axios.get("/lists/" + listID).then(response => {
      return response.data.data;
    });
  },

  getUnrankedItems(listID) {
    return axios.get("/lists/" + listID + "/items/unranked").then(response => {
      return response.data.data.items;
    });
  },

  getAllTypes() {
    return axios.get("/lists/types").then(response => {
      return response.data.data.types;
    });
  },

  saveList(list) {
    return axios
      .put("/lists/" + list.id, {
        list: list
      })
      .then(() => {
        return true;
      });
  },

  createList(list) {
    return axios
      .post("/lists", {
        list: list
      })
      .then(response => {
        return response.data.data.list;
      });
  },

  getRankedList(listID) {
    return axios.get("/lists/" + listID + "/order").then(response => {
      return response.data.data;
    });
  },

  saveRankedItems(listID, rankedItems) {
    return axios
      .put("/lists/" + listID + "/order", {
        rankedItems: rankedItems
      })
      .then(response => {
        return response.data.status;
      });
  },

  addItemToList(listId, imdbId) {
    return axios
      .post("/lists/" + listId + "/items", {
        imdbId: imdbId
      })
      .then(response => {
        return response.data.status;
      });
  },

  removeItemFromList(listId, imdbId) {
    return axios.delete("/lists/" + listId + "/items/" + imdbId).then(response => {
      return response.data.status;
    });
  },

  deleteList(listId) {
    return axios.delete("/lists/" + listId).then(response => {
      return response.data.status;
    });
  },

  getUsers(listId) {
    return axios.get("/lists/" + listId + "/users").then(response => {
      return response.data.data;
    });
  },

  saveUser(listId, userId, roleId) {
    return axios
      .put("/lists/" + listId + "/users/" + userId, {
        roleId: roleId
      })
      .then(response => {
        return response.data.data;
      });
  },

  deleteUser(listId, userId) {
    return axios.delete("/lists/" + listId + "/users/" + userId).then(response => {
      return response.data.data;
    });
  },

  addUser(listId, email) {
    return axios
      .post("/lists/" + listId + "/users/", {
        email: email
      })
      .then(response => {
        return response.data.data;
      });
  }
};
