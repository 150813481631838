<template>
  <div>
    <NavBar>
      <template v-slot:desktop-nav>
        <button class="btn btn-nav btn-yellow my-2 my-sm-0" data-toggle="modal" data-target="#list-create-modal">
          <i class="fas fa-plus-circle mr-2"></i>
          Create List
        </button>
        <router-link class="btn btn-nav btn-yellow my-2 my-sm-0 ml-3" :to="'/predictions'">
          <i class="fas fa-thumbtack mr-2"></i>
          Predictions
        </router-link>
      </template>
      <template v-slot:mobile-nav>
        <a class="mobile-nav-icon btn-nav btn btn-yellow w-100" data-toggle="modal" data-target="#list-create-modal">
          <i class="fas fa-plus"></i>
          Create A List
        </a>
        <router-link class="mobile-nav-icon btn-nav btn btn-yellow w-100" :to="'/predictions'">
          <i class="fas fa-thumbtack"></i>
          Predictions
        </router-link>
        <a class="mobile-nav-icon btn-nav-green btn btn-green w-100" data-toggle="modal" data-target="#account-settings">
          <i class="fas fa-user-cog"></i>
          Account Settings
        </a>
      </template>
    </NavBar>
    <div class="container home">
      <div class="movie-list-header">
        <h1>LISTS</h1>
      </div>
      <ListCard v-cloak v-for="list in lists" :key="list" :list="list"></ListCard>
      <div v-if="lists.length == 0">
        <h2 class="text-center">
          No lists found
          <i class="fas fa-frown"></i>
          <br />
          Please create a list
          <a data-toggle="modal" data-target="#list-create-modal">here</a>
        </h2>
      </div>
      <ListSettings :list="createList" :createMode="true" @created="created"></ListSettings>
    </div>
  </div>
</template>

<script>
import ListCard from "@/components/ListCard.vue";
import ListService from "../services/ListService";
import NavBar from "@/components/NavBar.vue";
import ListSettings from "@/components/ListSettings.vue";

export default {
  name: "Lists",
  data() {
    return {
      lists: [],
      createList: {
        listName: "",
        description: "",
        listTypeId: 1
      }
    };
  },
  components: {
    ListCard,
    NavBar,
    ListSettings
  },
  beforeRouteEnter(_to, _from, next) {
    ListService.getAllLists()
      .then(test => {
        next(vm => (vm.lists = test));
      })
      .catch(error => {
        return error.handleError();
      });
  },
  methods: {
    created(list) {
      this.lists.push(list);
    }
  }
};
</script>

<style scoped>
.movie-item-body {
  margin-left: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
