<script>
export default {
  name: "Verify",
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      vm.$store
        .dispatch("verifyEmail", {
          token: to.query.token,
          email: to.query.email
        })
        .then(() => {
          vm.$toast.success("Successfully verified your email address");
        })
        .catch(error => {
          error.handleError();
        })
        .finally(() => {
          if (vm.$store.state.user) {
            vm.$router.push({ path: "/lists" });
          } else {
            vm.$router.push({ path: "/login" });
          }
        });
    });
  }
};
</script>

<style scoped></style>
