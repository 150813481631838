<template>
  <div class="container login text-center">
    <div v-if="!canReset">
      <div class="movie-list-header mt-0">
        <h1>Password Reset</h1>
        <h4>Your password reset token is no-longer valid.</h4>
        <h5>
          Please
          <router-link to="/login">login</router-link>
          or
          <a @click="resetModalOpen = true">request a new reset email</a>
        </h5>
      </div>
      <ForgotPasswordModal :open="resetModalOpen" @closed="resetModalOpen = false" />
    </div>
    <div v-if="canReset">
      <div class="movie-list-header mt-0">
        <h1>Please enter a new password</h1>
      </div>
      <form class="form-signin" id="app">
        <p v-cloak style="color: red;">{{ errorMessage }}</p>
        <label for="new-password" class="sr-only">New Password</label>
        <div class="input-group has-validation">
          <input
            type="password"
            id="new-password"
            name="new-password"
            class="form-control"
            placeholder="New Password"
            required
            autofocus
            v-model="newPassword"
          />
          <div class="invalid-feedback">
            Your password must be between 8 and 32 characters, and contain both letters and numbers.
          </div>
        </div>
        <label for="new-password-confirm" class="sr-only">Confirm New Password</label>
        <div class="input-group has-validation">
          <input
            type="password"
            id="new-password-confirm"
            name="new-password-confirm"
            class="form-control"
            placeholder="Confirm New Password"
            required
            v-model="newPasswordConfirm"
          />
          <div class="invalid-feedback">
            This does not match the password above, please try again.
          </div>
        </div>
        <button type="button" class="btn btn-lg btn-primary btn-block" v-on:click="resetPassword">
          Reset Password
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import AccountService from "../services/AccountService";
import ForgotPasswordModal from "@/components/ForgotPasswordModal.vue";
import { isValidPassword } from "../utilities/validation";
import $ from "jquery";

export default {
  name: "PasswordReset",
  data() {
    return {
      canReset: false,
      newPassword: "",
      newPasswordConfirm: "",
      email: "",
      token: "",
      resetModalOpen: false
    };
  },
  components: {
    ForgotPasswordModal
  },
  methods: {
    resetPassword() {
      // Ensuring that the passwords are valid.
      if (isValidPassword(this.newPassword)) {
        $(`#new-password`).removeClass("is-invalid");

        // Ensuring the passwords confirm
        if (this.newPassword == this.newPasswordConfirm) {
          $(`#new-password-confirm`).removeClass("is-invalid");

          AccountService.resetPassword(this.email, this.token, this.newPassword)
            .then(() => {
              this.$toast.success("Successfully Reset Password");

              this.$router.push({ path: "/Login" });
            })
            .catch(error => {
              return error.handleError();
            });
        } else {
          $(`#new-password-confirm`).addClass("is-invalid");
        }
      } else {
        $(`#new-password`).addClass("is-invalid");
      }
    }
  },
  beforeRouteEnter(to, _from, next) {
    AccountService.canResetPassword(to.query.email, to.query.token)
      .then(() => {
        next(vm => {
          vm.canReset = true;
          vm.email = to.query.email;
          vm.token = to.query.token;
        });
      })
      .catch(error => {
        next();
        return error.handleError();
      });
  }
};
</script>

<style scoped>
.login {
  height: calc(100vh - 136px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/*                    */
/*      Login         */
/*                    */
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
