<template>
  <div>
    <NavBar>
      <template v-slot:desktop-nav>
        <div v-cloak class="d-flex align-items-center" v-if="list">
          <ItemSearch v-if="hasRole(Role.MEMBER)" :listId="id" />
          <router-link v-if="hasRole(Role.MEMBER)" class="btn btn-yellow btn-nav my-2 my-sm-0 ml-2" :to="'/lists/' + list.id + '/order'">
            <i class="fas fa-sort-amount-up"></i>
          </router-link>
          <a v-if="hasRole(Role.ADMIN)" class="btn btn-yellow btn-nav my-2 my-sm-0 ml-2" data-toggle="modal" data-target="#user-management">
            <i class="fas fa-users"></i>
          </a>
          <a v-if="hasRole(Role.ADMIN)" class="btn btn-yellow btn-nav my-2 my-sm-0 ml-2" data-toggle="modal" data-target="#list-create-modal">
            <i class="fas fa-cog" aria-hidden="true"></i>
          </a>
          <a v-if="!hasRole(Role.ADMIN)" class="btn btn-red btn-nav-red my-2 my-sm-0 ml-2" @click="leaveList">
            <i class="fas fa-running"></i>
          </a>
        </div>
      </template>
      <template v-slot:mobile-nav>
        <router-link class="mobile-nav-icon btn-nav btn btn-yellow w-100" to="/lists">
          <i class="fas fa-arrow-alt-circle-left"></i>
          Back
        </router-link>
        <router-link
          v-if="list != null && hasRole(Role.MEMBER)"
          class="mobile-nav-icon btn-nav-yellow btn btn-yellow w-100"
          :to="'/lists/' + list.id + '/order'"
        >
          <i class="fas fa-sort-amount-up"></i>
          Order
        </router-link>
        <a
          v-if="list != null && hasRole(Role.ADMIN)"
          data-toggle="modal"
          data-target="#user-management"
          class="mobile-nav-icon btn-nav-yellow btn btn-yellow w-100"
        >
          <i class="fas fa-users"></i>
          Members
        </a>
        <div
          v-if="list != null && hasRole(Role.ADMIN)"
          class="mobile-nav-icon btn-nav-yellow btn btn-yellow w-100"
          data-toggle="modal"
          data-target="#list-create-modal"
        >
          <i class="fas fa-cog"></i>
          Settings
        </div>
        <div v-if="list != null && !hasRole(Role.ADMIN)" class="mobile-nav-icon btn-nav-yellow btn btn-yellow w-100" @click="leaveList">
          <i class="fas fa-running"></i>
          Leave
        </div>
      </template>
    </NavBar>
    <div v-cloak class="container home" v-if="list">
      <div class="movie-list-header">
        <h1>{{ list.listName }} List</h1>
      </div>

      <ItemSearch v-if="hasRole(Role.MEMBER)" class="w-100 mb-3 d-md-none" :listId="id" />

      <div v-cloak class="alert alert-warning" role="alert" v-if="unrankedItems.length">
        <h4 class="alert-heading">UnRanked Movies</h4>
        <p class="m-0">
          You have some movies which you have not ranked. It is important that you rank all movies to ensure your vote is counted.
          <br />
          <router-link :to="'/lists/' + list.id + '/order'" class="alert-link">Click here</router-link>
          to rank movies!
        </p>
      </div>

      <div v-if="list.listItems.length == 0">
        <h2 class="text-center">
          No items found
          <i class="fas fa-frown"></i>
        </h2>
        <p class="text-center">Try Searching for your first item...</p>
      </div>

      <ItemCardLarge v-for="item in list.listItems" :key="item" :item="item.item" :listId="list.id" :dateAdded="item.createdAt"></ItemCardLarge>

      <ListSettings v-if="hasRole(Role.ADMIN)" :userRole="userRole" :list="list"></ListSettings>

      <UserManagement v-if="hasRole(Role.ADMIN)" :id="list.id" />

      <DeleteConfirm
        @delete-confirmed="leaveListConfirm"
        @closed="openConfirm = false"
        :value="list.listName"
        :title="'Leave ' + list.listName"
        text="Please retype the name in order to leave"
        buttonText="Leave"
        :open="openConfirm"
      />
    </div>
  </div>
</template>

<script>
import ItemCardLarge from "@/components/ItemCardLarge.vue";
import DeleteConfirm from "@/components/DeleteConfirm";
import NavBar from "@/components/NavBar.vue";
import ListSettings from "@/components/ListSettings.vue";
import ListService from "../../services/ListService";
import ItemSearch from "@/components/ItemSearch.vue";
import UserManagement from "./UserManagement";
import { computed } from "vue";
import { Role, hasRole } from "../../utilities/list.role";
import { userId } from "../../store/helpers";
import ListType from "../../utilities/listTypes";

export default {
  name: "List",
  props: ["id"],
  data() {
    return {
      list: null,
      userRole: null,
      unrankedItems: [],
      Role,
      openConfirm: false
    };
  },
  computed: {
    ...userId
  },
  provide() {
    return {
      userRole: computed(() => this.userRole)
    };
  },
  methods: {
    hasRole(role) {
      return hasRole(this.userRole.id, role);
    },
    leaveList() {
      this.openConfirm = true;
    },
    leaveListConfirm() {
      ListService.deleteUser(this.list.id, this.userId)
        .then(() => {
          this.$toast.success("Successfully Left " + this.list.listName);

          this.$router.push({ path: "/lists" });
        })
        .catch(error => {
          return error.handleError();
        });
    }
  },
  components: {
    ItemCardLarge,
    NavBar,
    ListSettings,
    ItemSearch,
    UserManagement,
    DeleteConfirm
  },
  mounted() {
    if (this.hasRole(Role.MEMBER) && this.list.listTypeId == ListType.RANKED) {
      ListService.getUnrankedItems(this.id)
        .then(items => {
          this.unrankedItems = items;
        })
        .catch(error => {
          return error.handleError();
        });
    }
  },
  beforeRouteEnter(_to, _from, next) {
    ListService.getList(_to.params.id)
      .then(result => {
        next(vm => {
          vm.list = result.list;

          vm.userRole = result.userRole;
        });
      })
      .catch(error => {
        return error.handleError();
      });
  }
};
</script>

<style scoped>
.search-box {
  border-radius: 0;
  color: #dedede;
  border: none;
  background-color: #6a72a0;
  background-image: url("https://www.transparenttextures.com/patterns/tweed.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  box-shadow: 5px 5px #4d5061;
  border: solid #4d5061;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  margin-right: 20px !important;
}

.search-box:hover {
  box-shadow: 5px 5px #c5a531;
  border-color: #e8c547;
}

.search-box:focus {
  background-color: #6a72a0;
  box-shadow: 0 0 0 0.2rem #c5a531;
  border-color: #e8c547;
  color: #e8c547;
}

.search-box::placeholder {
  color: #dedede;
}

.search-box::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
  content: "wefwe";

  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  /* background: red; */
}
</style>
