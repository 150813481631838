import axios from "axios";

export default {
  searchItems(listId, query) {
    return axios
      .get("/items", {
        params: {
          listId,
          query
        }
      })
      .then(response => {
        return response.data.data.items;
      });
  },

  getItem(listId, itemId) {
    return axios
      .get("/items/" + itemId, {
        params: {
          listId
        }
      })
      .then(response => {
        return response.data.data.item;
      });
  },

  rateItem(itemId, rating) {
    return axios.post(`/items/${itemId}/rate`, {
      rating
    });
  }
};
