<template>
  <div class="form-inline my-2 my-lg-0">
    <input
      class="form-control mr-sm-2 search-box"
      type="search"
      placeholder="Search"
      aria-label="Search"
      name="searchQuery"
      v-model="searchQuery"
      @keyup.enter="search"
    />
    <button class="btn btn-yellow btn-nav my-2 my-sm-0" @click="search">
      <i class="fa fa-search" aria-hidden="true"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "ItemSearch",
  props: ["listId"],
  data() {
    return {
      searchQuery: ""
    };
  },
  methods: {
    search() {
      this.$router.replace({ path: "/items", query: { listId: this.listId, query: this.searchQuery } });
    }
  }
};
</script>

<style scoped>
/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}
</style>
