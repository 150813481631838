<template>
  <footer>
    <!-- <hr class="mt-0" />
    <div class="container">
      <p>
        <span style="float: left;">Powered By <a href="https://www.omdbapi.com/">OMDb API</a></span
        ><span style="float: right;">&copy; <a href="https://leonwilberforce.com">Leon Wilberforce</a> &bull; 2020</span>
      </p>
    </div> -->
  </footer>
</template>

<script>
export default {
  name: "Footer"
  //   props: {
  //     msg: String
  //   }
};
</script>

<style scoped>
/*                    */
/*      Footer        */
/*                    */
footer {
  /* height: 60px;
  background-color: #30323d;
  color: #4d5061; */
}
</style>
