<template>
  <router-link :to="'/lists/' + list.id" class="item-card item-card-action">
    <div class="movie-item-body">
      <div class="row w-100 m-0">
        <div class="col-md-6 align-self-center">
          <div class="list-details">
            <h2 class="mb-0 mb-lg-1">
              {{ list.listName }}
              <span class="year">- Shared</span>
            </h2>
            <p class="mb-1">{{ list.description }}</p>
            <div class="w-100 d-md-block d-none">
              <ul class="p-0 m-0" style="float: left; list-style: none;">
                <li>
                  <b>Role:</b>
                  {{ list.role.roleName.toUpperCase() }}
                </li>
                <li>
                  <b>Categories:</b>
                  Movies, TV Shows and Games
                </li>
                <li>
                  <b>Items:</b>
                  {{ list.itemCount }}
                </li>
                <li>
                  <b>Members:</b>
                  {{ list.userCount }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 align-self-center">
          <div class="d-flex justify-content-end w-100">
            <div class="list-item-poster" v-for="item in list.items" :key="item" :style="{ 'background-image': 'url(' + item.poster + ')' }"></div>
          </div>
        </div>
      </div>
      <!-- <div class="row align-items-center list-thumbnails-container">
        <div class="list-thumbnails mr-5">
          <img
            class="list-item-thumbnail"
            v-for="(item, index) in list.items"
            :key="item"
            v-bind:src="item.poster"
            v-bind:style="{ left: '' + (list.items.length - index) * 30 + 'px' }"
          />
        </div>
      </div> -->
    </div>
    <!-- <small class="date" v-bind="listItem.addedWhen | getDateAdded "></small> -->
    <!-- <small class="date">Unknown</small> -->
  </router-link>
</template>

<script>
export default {
  name: "ListCard",
  props: {
    list: Object
  }
};
</script>

<style scoped>
/*                    */
/*      Lists         */
/*                    */
img.list-item-thumbnail {
  height: 150px;
  width: 99.75px;
}

img.list-item-thumbnail {
  transform: rotate(-3deg);
  position: relative;
}

.list-thumbnails {
  flex-grow: 1;
  text-align: right;
}

img.list-item-thumbnail:nth-child(3n + 0) {
  transform: rotate(5deg);
}

.list-item-poster {
  height: 150px;
  width: 99.75px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -60px;
  transform: rotate(-3deg);
}

.list-item-poster:nth-child(3n + 0) {
  transform: rotate(5deg) !important;
}

.form-group label {
  color: #f5f6f4;
  font-weight: bold;
}

.movie-item-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

b {
  color: #e8c547;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  /* .movie-item-body {
    flex-direction: column;
  } */
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .movie-item-body {
    flex-direction: column;
  }

  .list-thumbnails {
    margin: 0 !important;
  }
  .list-item-poster {
    height: 77px !important;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .item-card small.date {
    left: 20px;
    bottom: 10px;
  }
  .list-thumbnails {
    margin-left: -129px !important;
  }
  .list-item-poster {
    height: 130px;
    margin-left: 0;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .movie-item-body {
    /* background: red; */
  }

  /* .movie-item-body {
    flex-direction: column;
    align-items: baseline;
  } */

  .list-details {
    /* text-align: center; */
  }

  .list-details ul li {
    display: inline-block;
  }

  img.list-item-thumbnail {
    height: 99px;
    width: 66.43px;
  }

  .list-thumbnails {
    text-align: left;
  }

  .list-thumbnails-container {
    width: 100%;
  }

  h1 {
    font-size: 2.5rem;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .list-thumbnails {
    margin-left: -66px;
  }
}
</style>
