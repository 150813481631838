const isValidEmail = email => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

const isValidPassword = password => {
  // Minimum 8 characters
  // 1 uppercase letter
  // 1 lowercase letter
  // 1 number
  return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/.test(password);
};

export { isValidEmail, isValidPassword };
