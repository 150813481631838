import { createStore } from "vuex";
import AccountService from "../services/AccountService";
import { auth } from "./auth";
import { onAuthStateChanged } from "firebase/auth";
import FirebaseService from "../services/FirebaseService";

export default createStore({
  state: {
    user: null
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      (state.user = userData), localStorage.setItem("user", JSON.stringify(userData));
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem("user");
      location.reload();
    },
    VERIFIED_EMAIL(state) {
      state.user.emailVerified = true;
      localStorage.setItem("user", JSON.stringify(state.user));
    }
  },
  actions: {
    login({ commit }, credentials) {
      return FirebaseService.authenticate(credentials.email, credentials.password).then(user => {
        commit("SET_USER_DATA", user);
      });
    },
    logout({ commit }) {
      commit("CLEAR_USER_DATA");
      return auth.signOut();
    },
    saveDeatils({ commit }, details) {
      return AccountService.saveDetails(details.displayName, details.email).then(data => {
        commit("SET_USER_DATA", data);
      });
    },
    verifyEmail({ commit }, details) {
      let self = this;
      return AccountService.verifyEmailAddress(details.email, details.token).then(() => {
        if (self.getters.loggedIn) {
          commit("VERIFIED_EMAIL");
        }
      });
    },
    checkAuth({ commit }) {
      return new Promise(resolve => {
        onAuthStateChanged(auth, user => {
          if (user) {
            commit("SET_USER_DATA", user);
          } else {
            commit("SET_USER_DATA", null);
          }
          resolve(user);
        });
      });
    }
  },
  modules: {},
  getters: {
    loggedIn(state) {
      return !!state.user;
    },
    userId(state) {
      if (state.user == null) {
        return null;
      } else {
        return state.user.uid;
      }
    },
    email(state) {
      if (state.user == null) {
        return null;
      } else {
        return state.user.email;
      }
    },
    emailVerified(state) {
      if (state.user == null) {
        return null;
      } else {
        console.log(state.user);
        return state.user.emailVerified;
      }
    },
    displayName(state) {
      if (state.user == null) {
        return null;
      } else {
        return state.user.displayName;
      }
    }
  }
});
